// create order done component with orderDetails
import React, {useEffect} from 'react';
import CheckoutLayout from '../../components/checkoutLayout';
import { Link } from 'gatsby';
import { useCart } from 'react-use-cart';
import FinishedOrder from '../../components/finishedOrder';

const OrderDone = ({ location }) => {
    if(!location || !location.state) {
      // You can add optional redirect to home page here
      return (<div className='container'>
        <p>Моля, първо завършете поръчка <Link className='text-muted' to="/cart">Към количката</Link></p>
      </div>);
    }
    const { emptyCart } = useCart();
    const {orderDetails, orderId } = location.state;
    const {firstName, lastName} = orderDetails.customerInfo;
    const {totalPrice, deliveryOption} = orderDetails;
    useEffect(() => {
      emptyCart();
    });
    return (<CheckoutLayout>
          <FinishedOrder firstName={firstName} lastName={lastName} totalPrice={totalPrice} deliveryOption={deliveryOption} orderDetails={orderDetails} orderId={orderId}/>
        </CheckoutLayout>)
}

export default OrderDone;
