// create basic layout for checkout page with logo and contact details
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import Alert from "./alert";
import "./layout.scss";

const CheckoutLayout = ({children}) => {
  return (
    <div className="d-flex flex-column min-vh-100 text-break" id="layout">
      <Helmet>
        <html lang="bg"/>
      </Helmet>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <Link to="/">
              <StaticImage
                alt="Bella Gourmet logo"
                src="../images/icon.png"
                className="navbar-brand"
              />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="btn btn-lg btn-secondary text-white"to="/">Обратно в магазина</Link>
                </li>
                <span className="navbar-text mx-5">
                  <a id="#phone" href="tel:+359878272100" className="button is-primary has-text-white"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg> +359 878 272 100
                  </a>
                </span>
              </ul>
            </div>
          </div>
        </nav>
        <Alert enabled />
      </header>
      <span className="border border-light m-1"></span>
      <main className="flex-grow-1">
        {children}
      </main>
      <span className="border border-light m-1"></span>
      <footer className="text-center text-lg-start">
        <section className="container">
        <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link to="/" className="text-secondary">Начало</Link>
                </li>
                <li className="nav-item">
                  <Link to="/terms" className="text-secondary">Общи условия</Link>
                </li>
                <li className="nav-item">
                  <Link to="/delivery" className="text-secondary">Доставка</Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="text-secondary">Контакти</Link>
                </li>
                <li className="nav-item">
                  <p>+359 878 272 100</p>
                </li>
        </ul>
        </section>
        <section className="container">
          <p className="text-muted">Copyright &copy; {new Date().getFullYear()}. All Rights Reserved</p>
        </section>
      </footer>
    </div>
  );
}
export default CheckoutLayout;
