import React from "react";
import { formatPrice } from '../utils/index';

const FinishedOrder = ({firstName, lastName, totalPrice, deliveryOption, orderDetails, orderId}) => (
  <div className='container'>
          <h1 className='text-center'>Поръчката е приета</h1>
          <p><strong>{firstName} {lastName}</strong> Вие направихте поръчка за <strong>{formatPrice(totalPrice + deliveryOption.price)}</strong></p>
          {orderDetails.paymentOption === "cash" && (
            <>
              <p>Стойността на поръчката трябва да бъде заплатена на куриера при получаване на стоката.</p>
              <p>Ако имате някакви въпроси относно направените от Вас поръчки, ще се радваме да Ви помогнем!</p>
              <p><strong>Благодарим Ви, че ползвате нашия магазин.</strong></p>
            </>
          )}
          {orderDetails.paymentOption === "bankTransfer" && (
            <>
              <p>Може да платите по банков път, превеждайки дължимата сума по следната банкова сметка: </p>
              <p className='fs-4'>"УниКредит Булбанк" АД</p>
              <p className='fs-3'>IBAN: <strong>BG08UNCR70001523146015</strong></p>
              <p className='fs-3'> ВIС кода на банката: <strong>UNCRBGSF</strong></p>

              <p className='fs-3'> Получател на плащането: <strong> Белла травел България ЕООД </strong></p>

              <p className='fs-4'> В графата "Основание за плащане" ЗАДЪЛЖИТЕЛНО трябва да попълните номер на поръчката <strong>{orderId}</strong> Ако не се попълни номер идентификацията на превода се затруднява и е възможно поръчката да не бъде активирана. 
              </p>
              <p>Ако имате някакви въпроси относно направените от Вас поръчки, ще се радваме да Ви помогнем!</p>
              <p><strong>Благодарим Ви, че ползвате нашия магазин.</strong></p>
            </>
          )}
          <div className='text-uppercase'>
            <p>Номерът на Вашата поръчка е:</p>
          </div>
          <p><span className='p-1 bg-secondary fs-1 col-6'>{orderId}</span></p>
          <p>Код на доставка: {orderDetails.deliveryOption.type}</p>
        </div>
);

export default FinishedOrder;